import { createJSONStorage } from "jotai/utils";

// Jotai weirdly doesn't export this or give you access to its generic properties
export type Storage<Value> = {
  getItem: (key: string) => Value | Promise<Value>;
  setItem: (key: string, newValue: Value) => void | Promise<void>;
  delayInit?: boolean;
  subscribe?: (key: string, callback: (value: Value) => void) => () => void;
};

export const atomSessionStorage = createJSONStorage(() => ({
  getItem: (key) => {
    const strVal = sessionStorage.getItem(key);
    if (!strVal) return undefined;
    return JSON.parse(strVal);
  },
  setItem: (key, value) => sessionStorage.setItem(key, JSON.stringify(value)),
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})) as Storage<any>;

export const SUPPORT_EMAIL = "support@reclaim.ai";

export const LOCAL_STORAGE_AUTH_USER = "auth.user";
export const LOCAL_STORAGE_AUTH_REAUTH = "auth.reauth";
export const LOCAL_STORAGE_AUTH_ATTEMPTS = "auth.attempts";
export const LOCAL_STORAGE_AUTH_REDIRECT = "auth.redirect";
export const LOCAL_STORAGE_AUTH_REF = "auth.ref";
export const LOCAL_STORAGE_AUTH_UTM = "auth.utm";
export const LOCAL_STORAGE_AUTH_ADMIN = "auth.admin"
export const LOCAL_STORAGE_AUTH_IMPERSONATE = "auth.impersonate"
export const LOCAL_STORAGE_AUTH_IMPERSONATE_ERROR = "auth.impersonate.error"
